//=================================================
// Links
//=================================================

a {
    color: $body_links_color;
    @extend .transition-all;
    
    &:hover {
        color: $body_links_hover_color;
        text-decoration: none;
        @extend .transition-all;
    }

    // Small
    &.a-small {
        font-size: $f_size_df;
    }

    // Large
    &.a-large {
        font-size: 1.316rem;
        
        @media #{$media_xl} {
            font-size: 1rem;
        }
    }
}
