//=================================================
// Services
//=================================================

.service-item {
	position: relative;
	text-align: center;

	.service-item-title {
		position: absolute;
		top: 50%;
		left: 50%;
		@include transform(translate(-50%, -50%));
		color: $body_text_tertiary_color;
		width: 100%;
		padding: $space_sm;
		z-index: 2;
	}

	.img {
		&.object-fit {
			&:before {
				padding-top: 50%;
			}
		}
	}

	.img-bg-color {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		background: $body_main_element_secondary_color;
		opacity: 0.5;
	}
}
