//=================================================
// Iframe
//=================================================

iframe {
    width: 100%;
}

[class*='-block-embed'] {
    overflow: hidden;
    overflow-x: scroll;
}
