//=================================================
// Clients
//=================================================

.clients {
	padding-top: 4.211rem;
	padding-bottom: 4.211rem;

	.clients-items {
		@extend .d-flex;
		@extend .justify-content-between;
		@extend .align-items-center;
		margin-top: -$space_md / 2;
		margin-bottom: -$space_md / 2;
		margin-left: -3.5%;
		margin-right: -3.5%;

		@media #{$media_xxl} {
			margin-left: -2.2%;
			margin-right: -2.2%;
		}

		@media #{$media_lg} {
			flex-wrap: wrap !important;
			justify-content: space-around !important;
		}

		@media #{$media_sm} {
			margin-left: -$wrapper_p_l_sm;
			margin-right: -$wrapper_p_r_sm;
		}

		.clients-item {
			@extend .d-flex;
			width: 100%;
			padding-left: 3.5%;
			padding-right: 3.5%;
			padding-top: $space_md / 2;
			padding-bottom: $space_md / 2;

			@media #{$media_xxl} {
				padding-left: 2.2%;
				padding-right: 2.2%;
			}

			.clients-item-content {
				max-width: 11.632rem;
				margin: auto;

				@media #{$media_lg} {
					max-width: 9.5rem;
				}

				img {
					max-width: 100%;
					max-height: 5.579rem;
				}
			}

			@media #{$media_lg} {
				flex: 0 0 33%;
				max-width: 33%;
			}

			@media #{$media_sm} {
				flex: 0 0 50%;
				max-width: 50%;
				padding-left: $wrapper_p_l_sm;
				padding-right: $wrapper_p_r_sm;
			}
		}
	}
}

.clients-bottom-no-space {
	margin-bottom: -$main_padding_b;
}
