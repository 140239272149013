//=================================================
// About
//=================================================

.about-img {
	.img {
		min-height: 181px;

		&.object-fit {
			&:before {
				padding-top: 50.66%;
			}
		}
	}
}

@media #{$media_sm} {
	.align-right, .align-left {
		text-align: center !important;
		padding-bottom: 10px !important;
	}

	.sm-align-center {
		text-align: center !important;
	}
}

.align-right {
	text-align: right;
}

.align-right button, .align-left button {
	margin-top: 0 !important;
}